import { Dispatch, SetStateAction } from 'react'

// This is the output type of the map function
import { ILink, ILinkWithClickHandler, IImage } from '@alteos/ui'

// These should be the input types of the map function
import { IHeaderContent, IHeroContent, IIndividualCTAContent } from '../../types/IBaseContentful'
import { mapCtaContent, ICtaParams } from '../../utils/contentMaps/ctaContent';

interface IHeroComponentProps {
  title: string
  subtitle?: string
  ctaButtons: ICtaParams | ICtaParams[] | null
  image: IImage | null
}

export const mapHeroSection = (
  heroContent: IHeroContent,
  changePopupState: Dispatch<SetStateAction<boolean>> | null = null
): IHeroComponentProps => {
  const { title, subTitle, image, multipleButtons } = heroContent
  return {
    title: title?.title,
    subtitle: subTitle?.subTitle,
    image: image,
    ctaButtons: mapCtaContent(multipleButtons, changePopupState)
  }
}
