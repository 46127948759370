import {
  IFAQContent,
  IIndividualCTAContent,
  IIndividualFAQContent,
} from '../../types/IBaseContentful'
import { ICtaParams, getCtaParams } from '../contentMaps/ctaContent'

interface IMapFaqSection {
  title: string
  items: {
    title: string
    content: string
  }[]
  ctaLink: ICtaParams | null
}

export const mapFaqSection = (data: IFAQContent): IMapFaqSection => ({
  title: data.title,
  items: data.content?.map((item: IIndividualFAQContent) => ({
    title: item.question.question,
    content: item.answer.answer,
  })),
  ctaLink: getCtaParams(data.cta as IIndividualCTAContent),
})
