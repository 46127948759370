/* eslint-disable no-magic-numbers */
import { captureException } from '@sentry/browser'
import { graphql } from 'gatsby'
import React, { Dispatch } from 'react'

import { Hero, Footer, getComponentForContentfulType } from '@alteos/ui'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { IBasePageContent } from '../types/IBaseContentful'
import { mapFaqSection } from '../utils/sectionMaps/faqSection'
import { mapFooterSection } from '../utils/sectionMaps/footerSection'
import { mapHeaderSection } from '../utils/sectionMaps/headerSection'
import { mapHeroSection } from '../utils/sectionMaps/heroSection'
import { mapMainSection } from '../utils/sectionMaps/mainSection'
import { mapSeoElements } from '../utils/sectionMaps/seoSection'
import socialLinks from '../utils/socialLinks'
import { mapComponentsToRender } from '../utils/componentsToRender'

const mapContent = (
  data: IBasePageContent,
  setPopupState: Dispatch<React.SetStateAction<boolean>>
) => {
  const hero = mapHeroSection(data.heroContent, setPopupState)
  const header = mapHeaderSection(data.headerContent)
  const footer = mapFooterSection(data.footerContent, socialLinks, setPopupState)
  const faq = mapFaqSection(data.faqContent)
  const seo = mapSeoElements(data)

  return {
    hero,
    header,
    faq,
    footer,
    seo,
  }
}

interface IProductLandingPage {
  pageData: IBasePageContent
}

const ProductLandingPage: React.FC<{ data: IProductLandingPage }> = ({ data }): JSX.Element => (
  <Layout>
    {({ setPopupState }) => {
      const content = mapContent(data.pageData, setPopupState)
      return (
        <>
          <Hero
            logoWithPartner={null}
            tagline="tagline"
            isLight={false}
            fullMobileLogo={false}
            dataTest="Hero"
            {...content.hero}
            headerContent={content.header}
            partnerName={null}
          />

          {mapComponentsToRender(data.pageData, setPopupState)}

          <Footer dataTest="footer" {...content.footer} />
          <Seo {...content.seo} noIndex />
        </>
      )
    }}
  </Layout>
)

export const query = graphql`
  query ($slug: String!) {
    pageData: contentfulProductPageContent(slug: { eq: $slug }) {
      headerContent {
        content {
          ctaLabel
          ctaTo
        }
      }
      heroContent {
        title {
          title
        }
        subTitle {
          subTitle
        }
        image {
          file {
            url
          }
        }
        # Hero content should only have multipleButtons as CTA, the other multipleCTAs, cta is not used
        # for this section and is unnecessary complexity
        multipleButtons {
          viewName
          ctaLabel
          ctaTo
          color
          openNewTab
          icon
        }
      }
      mainContent {
        ... on ContentfulSectionalFaqContent {
          viewName
          technicalDesignSystemConfig {
            sectionType
            withBackground
          }
          faqContent {
            viewName
            title
            content {
              answer {
                answer
              }
              question {
                question
              }
            }

            cta {
              viewName
              ctaTo
              ctaLabel
              color
              icon
              openNewTab
            }
          }
        }
        ... on ContentfulSectionQuote {
          viewName
          backgroundImage {
            title
            description
            file {
              url
            }
          }
          arrayOfSlides {
            viewName
            label {
              label
            }
            title {
              title
            }
          }
          withQuotes
          multipleButtons {
            viewName
            ctaLabel
            ctaTo
            color
            openNewTab
            icon
          }
          technicalDesignSystemConfig {
            sectionType
          }
        }

        ... on ContentfulSectionalDividerContent {
          viewName
          technicalDesignSystemConfig {
            sectionType
          }
          cta {
            viewName
            ctaLabel
            ctaTo
            icon
            color
            openNewTab
          }
        }
        ... on ContentfulSectionalContent {
          title {
            title
          }
          content {
            content
          }
          image {
            file {
              url
            }
          }
          components {
            ... on ContentfulCompleteCard {
              viewName
              mainTitle
              content {
                content
              }
            }
            ... on ContentfulProductPlanContent {
              mainTitle
              subtitle
              tagline
              defaultSetting {
                isBestseller
                selectedBorder
              }
              planFeatures {
                features {
                  label
                  checked
                }
              }
            }
            ... on ContentfulIndividualSectionalContent {
              title {
                title
              }
              content {
                content
              }
              image {
                file {
                  url
                }
              }
              cta {
                viewName
                ctaLabel
                ctaTo
                color
                openNewTab
                icon
              }
            }
          }
          multipleButtons {
            viewName
            ctaLabel
            ctaTo
            color
            openNewTab
            icon
          }
          # TODO: Create partial for this
          technicalDesignSystemConfig {
            detailedSection
            sectionType
            featureNumbered
            darkMode
            withCtaButton
            allProductFeaturesList
            twoColumns
            withBackground
            axaLogo
            type
            withOrderNumber
            styles {
              alignBoxes
            }
          }
          cta {
            ctaLabel
            ctaTo
            icon
            color
            openNewTab
          }
        }
      }
      faqContent {
        title
        content {
          answer {
            answer
          }
          question {
            question
          }
        }
        cta {
          ctaLabel
          ctaTo
        }
      }
      footerContent {
        copyright {
          copyright
        }
        content {
          ctaLabel
          ctaTo
        }
      }
      seoTitle
      seoDescription {
        seoDescription
      }
    }
  }
`

export default ProductLandingPage
