import { IHeaderContent } from '../../types/IBaseContentful'
import { mapCtaContent, ICtaParams  } from '../contentMaps/ctaContent'

interface IHeaderComponentProps {
  items: ICtaParams | ICtaParams[]
}

export const mapHeaderSection = (headerContent: IHeaderContent): IHeaderComponentProps => {
  return {
    items: mapCtaContent(headerContent.content),
  }
}
