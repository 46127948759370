// TODO: all errors in this file should be reported to sentry
import { Dispatch, SetStateAction } from 'react'

import {
  ISectionalContent,
  IFAQContent,
  IIndividualCTAContent,
  ISectionQuoteContent,
} from '../../types/IBaseContentful'
import { ICtaParams, mapCtaContent } from '../contentMaps/ctaContent'
import { mapFaqSection } from '../sectionMaps/faqSection'
import { mapCompleteCardContent } from './completeCardSection'

interface IMainSectionContent {
  content: Record<string, any>
  config: Record<string, any>
}

export const mapMainSection = (
  sectionContent: ISectionalContent,
  changePopupState: Dispatch<SetStateAction<boolean>> | null = null
): IMainSectionContent => {
  const {
    title,
    subtitle,
    content,
    image,
    technicalDesignSystemConfig,
    components,
    cta,
    multipleButtons,
  } = sectionContent

  // MAP SECTIONAL CONTENT FAQ SECTION
  if (sectionContent?.technicalDesignSystemConfig?.sectionType === 'questions') {
    return {
      content: mapFaqSection(sectionContent['faqContent'] as unknown as IFAQContent),
      config: technicalDesignSystemConfig,
    }
  }

  // MAP SECTIONAL CONTENT COMPLECTE CASRDS SECTION
  if (sectionContent?.technicalDesignSystemConfig?.sectionType === 'completeCardsSection') {
    return {
      content: mapCompleteCardContent(sectionContent, sectionContent?.technicalDesignSystemConfig?.detailedSection),
      config: technicalDesignSystemConfig,
    }
  }

  let result: any = {}
  result.title = title?.title
  result.content = content?.content
  result.subtitle = subtitle
  result.image = image

  //  There are 3 possible types of components: IIndividualSectionalContent and IProductPlanContent ISectionQuoteContent IFAQContent
  result.items = mapSectionComponents(components, changePopupState)

  // passing the result object here is dirty but I cannot think of a way to pass the key names without more logic
  const ctaResult = mapComponentCTA(
    cta,
    technicalDesignSystemConfig,
    multipleButtons,
    changePopupState
  )

  // If its Quote Section, we need to map the quote section
  if (sectionContent?.technicalDesignSystemConfig?.sectionType === 'quote') {
    return {
      content: {
        ...mapSectionQuote(sectionContent),
        ...ctaResult,
      },
      config: technicalDesignSystemConfig,
    }
  }

  return {
    content: { ...result, ...ctaResult },
    config: technicalDesignSystemConfig,
  }
}

const mapSectionComponents = (
  components: any,
  changePopupState: Dispatch<SetStateAction<boolean>> | null = null
) =>
  components?.map((component) => {
    const {
      title,
      content,
      image,
      cta,
      mainTitle,
      subtitle,
      tagline,
      defaultSetting,
      planFeatures,
      slug,
      disabled,
    } = component
    return {
      title: title?.title ?? mainTitle,
      subtitle: subtitle,
      tagline: tagline,
      isBestseller: defaultSetting?.isBestseller,
      selectedBorder: defaultSetting?.selectedBorder,
      features: planFeatures?.features,
      content: content?.content,
      image: image,
      url: slug,
      disabled: disabled,
      ctaButton: mapCtaContent(cta, changePopupState),
    }
  })

const mapComponentCTA = (
  cta?: IIndividualCTAContent,
  technicalDesignSystemConfig?: Record<string, any>,
  multipleButtons?: IIndividualCTAContent[],
  changePopupState?: Dispatch<SetStateAction<boolean>>
): {
  ctaButton?: ICtaParams | ICtaParams[] | undefined
  ctaLink?: ICtaParams | ICtaParams[] | undefined
  ctaButtons?: ICtaParams | ICtaParams[] | undefined
} => {
  // if cta and technicalDesignSystemConfig and multipleButtons are undefined, return null
  if (isNil(cta) && isNil(multipleButtons)) {
    return undefined
  }
  // Will render the cta as a button
  if (!isNil(cta) && !isNil(technicalDesignSystemConfig?.withCtaButton)) {
    return {
      ctaButton: mapCtaContent(cta, changePopupState),
    }
  }
  // will render the cta as a link
  if (!isNil(cta)) {
    return {
      ctaLink: mapCtaContent(cta, changePopupState),
    }
  }
  // will render the cta as a list of buttons
  if (!isNil(multipleButtons)) {
    return {
      ctaButtons: mapCtaContent(multipleButtons, changePopupState),
    }
  }
}

const mapSectionQuote = (sectionContent: ISectionQuoteContent) => {
  return {
    image: sectionContent?.backgroundImage,
    backgroundColor: sectionContent?.backgroundColor,
    slides: sectionContent?.arrayOfSlides.map((slide) => {
      return {
        title: slide?.title?.title,
        subtitle: slide?.subtitle?.subtitle,
        label: slide?.label?.label,
      }
    }),
    withQuotes: sectionContent?.withQuotes,
  }
}

const isNil = (value: any) => value === null || value === undefined
