import { captureException } from '@sentry/browser'
import React, { Dispatch, SetStateAction } from 'react'

import { getComponentForContentfulType } from '@alteos/ui'

import { IBasePageContent } from '../types/IBaseContentful'
import { mapMainSection } from './sectionMaps/mainSection'

export const mapComponentsToRender = (
  pageData: IBasePageContent,
  setPopupState: Dispatch<SetStateAction<boolean>> | null = null
): JSX.Element[] => {
  return pageData.mainContent.map((section, index) => {
    const sectionType = section?.technicalDesignSystemConfig?.sectionType
    const Component = getComponentForContentfulType(sectionType, (error) => {
      captureException(error)
    })

    const componentData = mapMainSection(section, setPopupState)

    return (
      <Component
        dataTest={`section-${sectionType}-${index}`}
        {...componentData.content}
        {...componentData.config}
      />
    )
  })
}
