import { IBasePageContent } from '../../types/IBaseContentful'

interface IMapSeoElements {
  title: string
  description: string | undefined
}

export const mapSeoElements = (data: IBasePageContent): IMapSeoElements => ({
  title: data.seoTitle,
  description: data.seoDescription !== null ? data.seoDescription.seoDescription : undefined,
})
