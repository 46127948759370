import { Dispatch, SetStateAction } from 'react'

import { IFooterContent } from '../../types/IBaseContentful'
import { mapCtaContent, ICtaParams } from '../contentMaps/ctaContent'
import socialLinks, { ISocialLink } from '../socialLinks'

interface IFooterComponentProps {
  copyright: string
  items: ICtaParams | ICtaParams[] | null
  socialLinks: ISocialLink[]
}

export const mapFooterSection = (
  footerContent: IFooterContent,
  sociallinks: ISocialLink[] = socialLinks,
  changePopupState: Dispatch<SetStateAction<boolean>> | null = null
): IFooterComponentProps => {
  return {
    copyright: footerContent?.copyright?.copyright,
    items: mapCtaContent(footerContent?.content, changePopupState),
    socialLinks: sociallinks,
  }
}
