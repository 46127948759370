import { ISectionalContent } from '../../types/IBaseContentful'

interface IMapCompleteCardContent {
  items: Array<Partial<any>>
  title?: string
}

export const mapCompleteCardContent = (
  data: ISectionalContent,
  detailedSection: boolean
): IMapCompleteCardContent => {
  if (detailedSection) {
    const items = data.components.map((card) => ({
      title: card.mainTitle,
      content: card.content.content,
      image: null,
    }))
    return {
      items: [
        {
          title: data.title.title,
          image: data.image ? data.image : null,
          price: data.subtitle ? data.subtitle : null,
          subtitle: data.content ? data.content.content : null,
          benefits: { items: [...items] } as unknown as any[],
          divider: true,
          twoColumns: true,
        },
      ],
    }
  }
  return {
    title: data?.title?.title,
    items: data?.components.map((card) => ({
      image: card?.image,
      title: card?.mainTitle,
      content: card?.content?.content,
      price: card?.price,
      divider: true,
      subtitle: card?.subtitle,
      label:
        card?.parameters && card?.parameters?.label
          ? {
              content: card.parameters.label.content,
              type: card.parameters.label.type,
            }
          : null,
    })),
  }
}
